<template>
  <mobilepage :title="$t('assetbag.desc79')">
    <div class="income-record">
      <div class="no-data" v-if="list.length === 0 && finished">
        <img src="../../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
      <van-list
        v-model:loading="loading"
        :loading-text="$t('quotes.desc20')"
        :finished-text="$t('common.desc5')"
        :finished="finished"
        @load="handleLoad"
        class="list"
        v-else
      >
        <div class="box" v-for="item of list" :key="item.id">
          <div class="item">
            <div class="name">{{ $t("assetbag.desc78") }}</div>
            <div class="val">{{ common.formatDate(item.releaseTime) }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("assetbag.desc120") }}</div>
            <div class="val">
              {{
                Number(item.withdrawTime) === 0
                  ? "--"
                  : common.formatDate(item.withdrawTime)
              }}
            </div>
          </div>
          <div class="item">
            <div class="name">{{ $t("assetbag.desc67") }}</div>
            <div class="val">{{ item.releaseAmount }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("assetbag.desc68") }}</div>
            <div class="val">{{ item.releaseCoinName }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("assetbag.desc66") }}</div>
            <div class="val">{{ $t("assetbag.desc121") }}</div>
          </div>
          <div class="item">
            <div class="name">{{ $t("assetbag.desc58") }}</div>
            <div class="val">
              {{
                item.status === 0
                  ? $t("assetbag.desc76")
                  : $t("assetbag.desc75")
              }}
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </mobilepage>
</template>
<script>
export default {
  data() {
    return {
      showNull: false,
      finished: false,
      loading: false,
      page: 1,
      pageSize: 5,
      totalPage: 0,
      total: 0,
      list: [],
    };
  },
  mounted() {
    if (Number(localStorage.getItem("ismobile")) === 0) {
      this.$router.push("/asset");
      this.$store.commit("SET_ASSETTYPE", 4);
    }
    this.getList(this.$route.params.id);
  },
  methods: {
    handleLoad() {
      this.page++;
      this.getList();
    },
    getList(_id) {
      if(_id!=0&&_id!=1){
        return false
      }
      this.$post(_id==0?this.URL.assetbag.releaseRecords:this.URL.assetbag.releaseRecordsDaot, {
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code !== 0) return;
        this.list = this.list.concat(res.data.list);
        const page = res.data.page;
        this.total = page.count;
        this.totalPage = page.totalPage;
        this.loading = false;
        if (this.list.length === this.total) {
          this.finished = true;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  span {
    display: block;
    margin: 0 auto;
    //font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    color: #a1a1a1;
  }
}
.income-record {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .data {
    margin: 12px 16px 14px;
    flex: 0 0 auto;
    border-radius: 8px;
    background: #181818;
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 16px;
    &-item {
      margin-top: 16px;
      width: 50%;
      display: flex;
      flex-direction: column;
      .name {
        color: #a8a8a8;
        //font-family: Bai Jamjuree;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        text-transform: capitalize;
      }
      .val {
        margin-top: 10px;
        color: #fff;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        &.amount {
          color: #c9fa5b;
        }
      }
    }
  }
  .list {
    min-height: 0;
    flex: 1;
    overflow-y: auto;
    padding: 0 16px;
    box-sizing: border-box;
    .box {
      border-radius: 8px;
      background: #181818;
      display: flex;
      flex-direction: column;
      padding: 0 16px 16px;
      box-sizing: border-box;
      margin-bottom: 10px;
      .item {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .name {
          color: rgba(255, 255, 255, 0.6);
          //font-family: Bai Jamjuree;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
          text-transform: capitalize;
        }
        .val {
          color: #fff;
          text-align: right;
          //font-family: Bai Jamjuree;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 13px;
          text-transform: capitalize;
        }
      }
    }
  }
}
</style>
